import type {FieldState, Payer, TransportReason, Vehicle} from '@ambler/shared'
import {getCustomizedTransportReasonLabel, isVehicle} from '@ambler/shared'
import gql from 'graphql-tag'
import type {DocumentNode} from 'graphql'
import type {
  A4H_getReasonLabelMfuFragment,
  A4H_getVehicleListMfuFragment,
  A4H_isPreferredMtWantedMfuFragment,
  A4H_missionDoctorMfuFragment,
  A4H_missionMfuNotesMfuFragment,
  A4H_patientAddressDetailsMfuFragment,
  A4H_patientAddressMfuFragment,
  A4H_patientBirthdateMfuFragment,
  A4H_patientIppMfuFragment,
  A4H_patientNirMfuFragment,
  A4H_patientPhoneMfuFragment,
  A4H_patientRoomMfuFragment,
  A4H_patientVisitIdMfuFragment,
} from './customization.generated'

export function getReasonLabel(mfu: A4H_getReasonLabelMfuFragment, reason: TransportReason, rawReason: string) {
  return getCustomizedTransportReasonLabel(reason, rawReason, mfu.transportReasonCustomizationGroups)
}
getReasonLabel.fragments = {
  mfu: gql`
    fragment A4H_getReasonLabelMfuFragment on MedicalFacilityUnit {
      id
      transportReasonCustomizationGroups {
        id
        index
        label
        transportReasonCustomizations {
          id
          index
          label
          reason
        }
      }
    }
  `,
}

export function getVehicleList(mfu: A4H_getVehicleListMfuFragment) {
  const labels: Partial<Record<Vehicle, string>> = {
    AMBULANCE: mfu.customization.ambulanceLabel,
    VSL: mfu.customization.vslLabel,
    TPMR: mfu.customization.tpmrLabel,
    BARIATRIC: mfu.customization.bariatricLabel,
    PARAMEDICAL: mfu.customization.paramedicalLabel,
    MEDICAL: mfu.customization.medicalLabel,
  }

  const needAmbulance = mfu.needAmbulance ?? false
  const needVsl = mfu.needVsl ?? false
  const needTpmr = mfu.needTpmr ?? false
  const needBariatric = mfu.needBariatric ?? false
  const needParamedical = mfu.needParamedical ?? false
  const needMedical = mfu.needMedical ?? false

  const choices = [
    needAmbulance && 'AMBULANCE',
    needVsl && 'VSL',
    needTpmr && 'TPMR',
    needBariatric && 'BARIATRIC',
    needParamedical && 'PARAMEDICAL',
    needMedical && 'MEDICAL',
  ].filter(isVehicle)

  return {choices, labels}
}
getVehicleList.fragments = {
  mfu: gql`
    fragment A4H_getVehicleListMfuFragment on MedicalFacilityUnit {
      id
      needAmbulance
      needVsl
      needTpmr
      needBariatric
      needParamedical
      needMedical
      customization {
        id
        ambulanceLabel
        vslLabel
        tpmrLabel
        bariatricLabel
        paramedicalLabel
        medicalLabel
      }
    }
  `,
}

export function isPreferredMtWanted(mfu: A4H_isPreferredMtWantedMfuFragment, payer: Payer) {
  return mfu.customization.missionPreferredMtRules
    .flatMap(rule => rule.payers)
    .map(({payer: payer_}) => payer_)
    .includes(payer)
}
isPreferredMtWanted.fragments = {
  mfu: gql`
    fragment A4H_isPreferredMtWantedMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        missionPreferredMtRules {
          id
          payers {
            id
            payer
          }
        }
      }
    }
  `,
}

type MfuFieldDefinition = {
  field:
    | 'patientNir'
    | 'patientIpp'
    | 'patientBirthdate'
    | 'patientPhone'
    | 'patientRoom'
    | 'patientVisitId'
    | 'patientAddress'
    | 'patientAddressDetails'
    | 'missionMfuNotes'
    | 'missionDoctor'
  defaultLabel: string
  fragment: DocumentNode
}

const makeFieldCustomizationHelper = <TFragment extends {customization: any}>({
  field,
  defaultLabel,
  fragment,
}: MfuFieldDefinition) => {
  return {
    fragment,
    useField(mfu: TFragment) {
      const state = mfu.customization[`${field}State`] as FieldState
      const label = mfu.customization[`${field}Label`] as string
      return {
        isUsed: state !== 'UNUSED',
        isRequired: state === 'USED_AND_REQUIRED',
        label: label || defaultLabel,
      }
    },
  }
}

export const customPatientNir = makeFieldCustomizationHelper<A4H_patientNirMfuFragment>({
  field: 'patientNir',
  defaultLabel: "Numéro de sécurité sociale de l'assuré",
  fragment: gql`
    fragment A4H_patientNirMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientNirState
        patientNirLabel
      }
    }
  `,
})

export const customPatientIpp = makeFieldCustomizationHelper<A4H_patientIppMfuFragment>({
  field: 'patientIpp',
  defaultLabel: 'IPP',
  fragment: gql`
    fragment A4H_patientIppMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientIppState
        patientIppLabel
      }
    }
  `,
})

export const customPatientBirthdate = makeFieldCustomizationHelper<A4H_patientBirthdateMfuFragment>({
  field: 'patientBirthdate',
  defaultLabel: 'Date de naissance',
  fragment: gql`
    fragment A4H_patientBirthdateMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientBirthdateState
        patientBirthdateLabel
      }
    }
  `,
})

export const customPatientPhone = makeFieldCustomizationHelper<A4H_patientPhoneMfuFragment>({
  field: 'patientPhone',
  defaultLabel: 'Numéro de téléphone',
  fragment: gql`
    fragment A4H_patientPhoneMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientPhoneState
        patientPhoneLabel
      }
    }
  `,
})

export const customPatientRoom = makeFieldCustomizationHelper<A4H_patientRoomMfuFragment>({
  field: 'patientRoom',
  defaultLabel: 'Chambre',
  fragment: gql`
    fragment A4H_patientRoomMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientRoomState
        patientRoomLabel
      }
    }
  `,
})

export const customPatientVisitId = makeFieldCustomizationHelper<A4H_patientVisitIdMfuFragment>({
  field: 'patientVisitId',
  defaultLabel: 'Numéro de séjour',
  fragment: gql`
    fragment A4H_patientVisitIdMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientVisitIdState
        patientVisitIdLabel
      }
    }
  `,
})

export const customPatientAddress = makeFieldCustomizationHelper<A4H_patientAddressMfuFragment>({
  field: 'patientAddress',
  defaultLabel: 'Adresse de domicile',
  fragment: gql`
    fragment A4H_patientAddressMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientAddressState
        patientAddressLabel
      }
    }
  `,
})

export const customPatientAddressDetails = makeFieldCustomizationHelper<A4H_patientAddressDetailsMfuFragment>({
  field: 'patientAddressDetails',
  defaultLabel: "Complément d'adresse",
  fragment: gql`
    fragment A4H_patientAddressDetailsMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        patientAddressDetailsState
        patientAddressDetailsLabel
      }
    }
  `,
})

export const customMissionMfuNotes = makeFieldCustomizationHelper<A4H_missionMfuNotesMfuFragment>({
  field: 'missionMfuNotes',
  defaultLabel: 'Note interne',
  fragment: gql`
    fragment A4H_missionMfuNotesMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        missionMfuNotesState
        missionMfuNotesLabel
      }
    }
  `,
})

export const customMissionDoctor = makeFieldCustomizationHelper<A4H_missionDoctorMfuFragment>({
  field: 'missionDoctor',
  defaultLabel: 'Médecin prescripteur',
  fragment: gql`
    fragment A4H_missionDoctorMfuFragment on MedicalFacilityUnit {
      id
      customization {
        id
        missionDoctorState
        missionDoctorLabel
      }
    }
  `,
})
